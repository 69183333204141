.manager-quota-page {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #D1840A 0%, rgba(255, 255, 255, 0.00) 438px), #FFF;
  padding-bottom: 20px;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    p {
      width: 100%;
      text-align: center;
      line-height: 44px;
      font-size: 18px;
      color: white;
      font-weight: 600;
    }
  }
  .content {
    width: 100%;
    margin-top: 16px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    .title {
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
    .amount {
      margin-top: 6px;
      color: #D1840A;
      font-size: 32px;
      font-weight: 600;
    }
    .content_row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      column-gap: 50px;
      margin-top: 32px;

      .content_row_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .t1 {
          font-size: 12px;
          color: #212121;
        }
        .t2 {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
          margin-top: 8px;
        }
        .button {
          width: 110px;
          height: 32px;
          border-radius: 32px;
          background-color: #D1840A;
          font-size: 14px;
          font-weight: 600;
          color: white;
          margin-top: 16px;
        }
        .t3 {
          font-size: 12px;
          color: #808080;
          margin-top: 16px;
        }
      }
    }
  }
  .promote-button {
    margin-top: 16px;
    width: 100%;
    height: 48px;
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #D1840A;
    margin-bottom: 30px;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  .list {
    width: 100%;

    .head {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      img {
        width: 120px;
      }
      p {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
        margin-top: 10px;
      }
    }
    .datas {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;
        padding: 12px 0;

        .icon {
          width: 48px;
          height: 48px;
        }
        .item_div {
          flex: 1;
          display: flex;
          flex-direction: column;
          row-gap: 5px;

          .item_row {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .t1 {
              font-size: 16px;
              color: #1B1B1B;
              font-weight: 600;
            }
            .t2 {
              font-size: 16px;
              color: #212121;
            }
            .t3 {
              font-size: 12px;
              color: #808080;
            }
          }
        }
      }
    }
  }
}
.promote-quota-page {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    p {
      width: 100%;
      text-align: center;
      line-height: 44px;
      font-size: 18px;
      color: #212121;
      font-weight: 600;
    }
  }
  .desc {
    width: 100vw;
    height: calc(100vw * 94 / 375);
    background-position: center;
    background-size: 100%;
    font-size: 14px;
    color: white;
    line-height: 1.5;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    white-space: pre-wrap;
  }
  .amount_div {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;

    img {
      width: 30px;
      height: 56px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .t1 {
        font-size: 14px;
        color: #000;
      }
      .t2 {
        font-size: 32px;
        color: #212121;
        font-weight: 600;
      }
    }
  }
  .operation_div {
    width: 100%;
    margin-top: 32px;
    background-color: #F7F7F7;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      font-size: 14px;
      color: #000;
    }
    div {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
        height: 40px;
      }
      p {
        font-size: 64px;
        color: #D1840A;
        font-weight: 600;
      }
    }
  }
  .name {
    margin-top: 32px;
    font-size: 24px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .input_div {
    width: 100%;
    margin-top: 16px;
    padding: 14px 16px;
    box-sizing: border-box;
    background-color: #F7F7F7;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .input {
      flex: 1;
      
      .adm-input-element {
        font-size: 16px;
        color: #212121;
        --placeholder-color: #808080;
      }
    }
    .all-button {
      font-size: 16px;
      color: #D1840A;
      margin-left: 16px;
    }
    .resend-button {
      font-size: 16px;
      color: #D1840A;
      margin-left: 16px;
    }
    .resend-button-disable {
      color: rgba(#D1840A, 0.5);
    }
  }
  .confirm-button {
    width: 100%;
    margin-top: 16px;
    height: 48px;
    border-radius: 48px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #D1840A;
  }
  .txt {
    margin-top: 32px;
    white-space: pre-wrap;
    width: 100%;
    font-size: 12px;
    color: #808080;
    line-height: 1.75;
  }
}