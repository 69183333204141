.manager-order-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
  }
  .subtitle {
    font-size: 18px;
    color: #212121;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 4px;
  }
  .info-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    column-gap: 12px;

    img {
      width: 16px;
      height: 16px;
      margin-top: 1px;
    }
    p {
      font-size: 14px;
      color: #212121;
      flex: 1;
      word-break: break-all;
    }
  }
  .fee-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    p {
      font-size: 14px;
      color: #212121;
    }
    .bold {
      font-weight: 600;
    }
  }
  .fee-last-item {
    p {
      font-size: 16px;
    }
  }
  .desc {
    margin-top: 16px;
    color: #212121;
    font-size: 12px;
    line-height: 1.75;
  }
  .line {
    width: 100vw;
    margin-top: 24px;
    height: 1px;
    background-color: #E6E6E6;
    margin-left: -16px;
  }
  .flex1 {
    flex: 1;
  }
  .order-result {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
    .t1 {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      margin-top: 12px;
    }
    .t2 {
      font-size: 14px;
      color: #808080;
      margin-top: 4px;
    }
  }
  .next-Button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin: 20px 0;
  }
}