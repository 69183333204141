.p_open {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 20px;
  box-sizing: border-box;

  &_image {
    width: 329px;
    height: 226px;
    margin-top: 40px;
  }
  &_title {
    font-size: 24px;
    color: #212121;
    font-weight: 600;
    margin-top: 20px;
  }
  &_content {
    font-size: 14px;
    color: #808080;
    line-height: 1.5;
    text-align: center;
    margin-top: 12px;
  }
  &_list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  &_item {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 28px;

    img {
      width: 48px;
      height: 48px;
      margin-right: 11px;
    }
    .number {
      color: #212121;
      font-size: 18px;
      font-weight: 600;

      span {
        color: red;
        font-size: 16px;
      }
    }
    .text {
      color: #808080;
      font-size: 14px;
      margin-top: 3px;
    }
  }
  &_register {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    margin-top: 32px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  &_login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
    font-size: 14px;
    color: #212121;

    span{
      color: #D1840A;
      font-weight: 600;
    }
  }
}