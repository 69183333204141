.pay-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--bgcolor, white);
    border: 1px solid var(--bdcolor, #E6E6E6);
    margin-top: 16px;

    img {
      width: 24px;
      height: 24px;
    }
    .icon {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: var(--bgcolor2, white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      font-size: 18px;
      color: var(--color, white);
      font-weight: 600;
      flex: 1;
    }
  }
}