.card-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  padding-bottom: 65px;
  box-sizing: border-box;

  .navbar {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #212121;
    }
    .t1 {
      font-size: 16px;
      font-weight: 600;
      color: #D1840A;
    }
  }
  .card-image {
    width: 329px;
    height: 226px;
    margin-top: 12px;
  }
  .explain-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .explain-item {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 28px;

      img {
        width: 48px;
        height: 48px;
        margin-right: 11px;
      }
      .number {
        color: #212121;
        font-size: 18px;
        font-weight: 600;

        span {
          color: red;
          font-size: 16px;
        }
      }
      .text {
        color: #808080;
        font-size: 14px;
        margin-top: 3px;
      }
    }
  }
  .tip {
    width: 100%;
    // text-align: center;
    // margin-top: 16px;
    font-size: 12px;
    line-height: 1.5;
    color: #808080;
		white-space: pre-wrap;
  }
  .activation-button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    margin: 20px 0;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  .result {
    position: sticky;
    left: 0;
    bottom: 65px;
    width: 100vw;
    height: 72px;
    background-color: var(--bgcolor);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 24px;
    padding-left: 24px;
    padding-right: 16px;
    box-sizing: border-box;

    img {
      width: 24px;
      height: 24px;
    }
    &_desc {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      flex: 1;

      p {
        font-size: 14px;
        color: var(--color, white);
        font-weight: var(--fw, 400);
      }
    }
    .btn {
      height: 32px;
      padding: 0 16px;
      color: #212121;
      font-size: 14px;
      font-weight: 600;
      background-color: white;
      border-radius: 32px;
    }
  }
  .result2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    
    img {
      width: 48px;
      height: 48px;
    }
    &_t1 {
      font-size: 24px;
      color: #212121;
      font-weight: 600;
      margin-top: 24px;
    }
    &_t2 {
      font-size: 14px;
      color: #808080;
      margin-top: 12px;
    }
  }
  .balance-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .available-div {
      display: flex;
      flex-direction: column;

      .t2 {
        font-size: 14px;
        color: #808080;
      }
      .balance {
        margin-top: 2px;
        font-size: 32px;
        color: #212121;
        font-weight: 600;
      }
    }
    .icons {
      display: flex;
      flex-direction: row;
      column-gap: 12px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .line {
    width: 100vw;
    height: 1px;
    background-color: #e6e6e6;
    margin-top: 24px;
  }
  .session-title {
    margin-top: 25px;
    font-size: 18px;
    color: #212121;
    width: 100%;
    font-weight: 600;
  }
  .recent-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 12px 0;

    .recent-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 14px 0;
      box-sizing: border-box;

      .item-content-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .name {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
        }
        .amount {
          font-size: 16px;
        }
        .time {
          font-size: 12px;
          color: #808080;
        }
        .status {
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }
}
.card-confirm-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;

  .navbar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    margin-top: 8px;
    width: 100%;
  }
  .session-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input {
      background-color: #F7F7F7;
      border-radius: 8px;
      margin-top: 6px;
      padding: 14px 16px;
      box-sizing: border-box;
      
      .adm-input-element {
        font-size: 16px;
        color: #212121;
      }
    }
  }
  .confirm-button {
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 16px;
  }
}
.card-info-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 20px;
  box-sizing: border-box;

  .navbar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    margin: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .copy-button {
      font-size: 14px;
      color: #D1840A;
      font-weight: 600;
    }
  }
  .session-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    .key {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
    }
    .value-div {
      margin-top: 8px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .key {
        font-size: 16px;
        color: #212121;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.add-founds-popup {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    color: #212121;
    font-size: 18px;
    margin-top: 16px;
    font-weight: 600;
  }
  .subtitle {
    color: #808080;
    font-size: 16px;
    margin-top: 40px;
    font-weight: 600;
  }
  .content {
    color: #808080;
    font-size: 14px;
    margin-top: 40px;
    font-weight: 600;
  }
  .menu-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;

    .menu-item {
      // width: calc(33.33% - 11px);
      width: calc(50% - 11px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 8px 0;
      margin-right: 16px;
      background-color: #F7F8F9;
      border-radius: 8px;
      border-width: 2px;
      border-style: solid;
      box-sizing: border-box;

      // &:nth-child(3n + 3) {
      //   margin-right: 0;
      // }
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
      .t1 {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
        margin-top: 15px;
      }
      .t2 {
        font-size: 14px;
        color: #808080;
        margin-top: 5px;
        margin-bottom: 20px;
      }
      .placehorder-txt {
        font-size: 14px;
        color: #808080;
      }
      .amount {
        font-size: 25px;
        color: #D1840A;

        span {
          display: inline-block;
          width: 2px;
          height: 20px;
          margin-left: 5px;
          background-color: #D1840A;
          -webkit-animation: blink 0.5s infinite;
          animation: blink 0.5s infinite;
        }
        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
  .input-div {
    width: 100%;
    margin-top: 50px;
    position: relative;

    .amount-div {
      width: 100%;
      height: 70px;
      font-size: 50px;
      color: #D1840A;
      font-weight: 600;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
        display: inline-block;
        width: 2px;
        height: 40px;
        margin-left: 5px;
        background-color: #D1840A;
        -webkit-animation: blink 0.5s infinite;
        animation: blink 0.5s infinite;
      }
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .max-button {
      font-size: 14px;
      font-weight: 600;
      color: #D1840A;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .input {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    
    .adm-input-element {
      font-size: 50px;
      color: #D1840A;
      font-weight: 600;
      --text-align: center;
    }
  }
  .pay-div {
    margin-top: 30px;
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 16px;
    box-sizing: border-box;

    .img1 {
      width: 32px;
      height: 32px;
    }
    .img2 {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      .t1 {
        font-size: 14px;
        color: #808080;
      }
      .t2 {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
    }
  }
  .confirm-button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-top: 20px;
  }
  .question-button {
    color: #D1840A;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
  }
}
.add-founds-token-popup {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .token-list {
    margin: 20px 0;
    width: 100%;

    .token-item {
      width: 100%;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 32px;
      }
      .name {
        font-weight: 600;
        font-size: 16px;
        color: #212121;
        margin: 0 12px;
        flex: 1;
      }
      .select-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.adm-number-keyboard-key.adm-number-keyboard-key-ok {
  background-color: #D1840A !important;
}
.card-modal {
  padding: 0;
  overflow-y: hidden;
  
  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
    }
    .content {
      width: 80%;
      font-size: 14px;
      line-height: 1.5;
      color: #808080;
      margin: 0 32px;
      text-align: center;
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #D1840A;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}
.adm-modal-footer.adm-space {
  display: none;
}
.adm-modal {
  z-index: 999999;
}
.open-card-way-popup {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    font-size: 16px;
    color: #212121;
    font-weight: 600;
    text-align: center;
    margin: 20px 0;
  }
  .way-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px;
    margin: 10px 0;

    .icon {
      width: 32px;
      height: 32px;
    }
    .txt-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 10px;

      .t1 {
        font-size: 18px;
        font-weight: 600;
      }
      .t2 {
        font-size: 14px;
        margin-top: 6px;
      }
    }
    .tag {
      width: 24px;
      height: 24px;
    }
  }
  .item1 {
    border: 1px solid transparent;
    background-color: #D1840A;
    margin-bottom: 50px;

    .txt-div {

      .t1 {
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 12px;
          color: white;
          padding: 3px 6px;
          background-color: #FAA805;
          border-radius: 4px;
          display: inline-block;
          margin-left: 6px;
        }
      }
      .t2 {
        color: rgba(white, 0.6);
      }
    }
  }
  .item2 {
    border: 1px solid #E6E6E6;
    background-color: white;
    margin-bottom: 50px;

    .txt-div {

      .t1 {
        color: #212121;
      }
      .t2 {
        color: #808080;
      }
    }
  }
}
.customer-popup {
  width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    color: #212121;
    font-size: 18px;
    margin-top: 16px;
    font-weight: 600;
  }
  .content {
    color: #808080;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
  }
  .code {
    width: 250px;
    height: 250px;
    margin-top: 20px;
  }
  .t1 {
    color: #CCCCCC;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.transfer-popup {
  width: 100vw;
  height: 90vh;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .title {
    color: #212121;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .session-div {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input-div {
      margin-top: 6px;
      width: 100%;
      background-color: #F7F7F7;
      border-radius: 8px;
      padding: 14px 16px;
      box-sizing: border-box;

      .input-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .symbol {
          font-size: 16px;
          color: #212121;
        }
        .input {
          flex: 1;
          
          .adm-input-element {
            font-size: 16px;
            color: #212121;
            --placeholder-color: #808080;
          }
        }
        .all-button {
          font-size: 16px;
          color: #D1840A;
          margin-left: 16px;
        }
        .resend-button {
          font-size: 16px;
          color: #D1840A;
          margin-left: 16px;
        }
        .resend-button-disable {
          color: rgba(#D1840A, 0.5);
        }
      }
      .value {
        margin-top: 16px;
        font-size: 12px;
        color: #808080;
      }
    }
    .network-list {
      margin-top: 12px;
      display: flex;
      flex-direction: row;

      .network-item {
        font-size: 12px;
        color: #808080;
        border: 1px solid #E6E6E6;
        border-radius: 16px;
        margin: 0 4px;
        padding: 8px 12px;
      }
      .network-item-sel {
        border-color: #D1840A;
        background-color: rgba(#D1840A, 0.1);
        color: #D1840A;
      }
    }
    .type-div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .key {
        font-size: 14px;
        color: #808080;
      }
      .value1 {
        font-size: 24px;
        color: #212121;
        font-weight: 600;
      }
      .value2 {
        font-size: 24px;
        color: #D1840A;
        font-weight: 600;
      }
      .value3 {
        font-size: 18px;
        color: #212121;
        font-weight: 600;
      }
    }
    .dollar {
      width: 100%;
      text-align: right;
      font-size: 14px;
      color: #808080;
      margin-top: 8px;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 32px 0;
  }
  .receive-address {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #212121;
    word-break: break-all;
    text-align: right;

    span {
      display: inline-block;
      color: #808080;
      margin-right: 100px;
    }
  }
  .txt {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    color: #808080;

    span {
      display: inline-block;
    }
  }
  .confirm-button2 {
    width: 100%;
    height: 48px;
    min-height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin: 20px 0;
  }
}
.card-fee-modal {
  padding: 0;
  overflow-y: hidden;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin: 20px 0;
    }
    .content {
      width: 100%;
      font-size: 14px;
      line-height: 1.5;
      color: #808080;
      padding: 0 28px;
      box-sizing: border-box;
      text-align: center;
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #D1840A;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}