.manager-pin-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
  }
  .subtitle {
    margin-top: 8px;
    font-size: 14px;
    color: #808080;
  }
  .session-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input {
      background-color: #F7F7F7;
      border-radius: 8px;
      margin-top: 6px;
      padding: 14px 16px;
      box-sizing: border-box;
      border: 1px solid #F7F7F7;
      
      .adm-input-element {
        font-size: 16px;
        color: #212121;
      }
      &:focus-within {
        border: 1px solid #212121;
      }
    }
    .input-error:not(focus-within) {
      border: 1px solid red;
    }
  }
  .flex1 {
    flex: 1;
  }
  .next-Button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
  }
  .input-name {
    width: 100%;
    font-size: 14px;
    color: #212121;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .input-container {
    width: 100%;
    height: 50px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    .input-div {
      flex: 1;
      --font-size: 16px;
      --color: #212121;
      
      .adm-input-element {
        line-height: 16px;
      }
    }
    .resend-button {
      font-size: 16px;
      color: #D1840A;
      margin-left: 10px;
    }
    .resend-button-disable {
      color: rgba(#D1840A, 0.5);
    }
  }
  .input-container-active {
    border-color: #D1840A;
  }
  .input-container-error {
    border-color: red;
  }
}
.m_modal_logout {
  padding: 0;
  overflow-y: hidden;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin-top: 20px;
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #EA3C32;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}
.adm-modal-footer.adm-space {
  display: none;
}