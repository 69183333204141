.mine-token-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .section-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    margin: 5px 0;

    p {
      font-size: 16px;
      font-weight: 400;
      color: #1B1B1B;
    }
    img {
      width: 18px;
    }
  }
}