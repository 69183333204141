.assets-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  box-sizing: border-box;
  padding-bottom: 100px;

  .title {
    font-size: 24px;
    color: #212121;
    font-weight: 600;
    margin-top: 24px;
  } 
  .banner-div {
    width: 100%;
    min-height: 110px;
    margin-top: 24px;
    position: relative;

    .bg-image {
      width: 100%;
    }
    .content-div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      padding: 20px 16px 24px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .est-value {
        font-size: 14px;
        color: #9EADFF;
      }
      .value {
        font-size: 32px;
        color: white;
        font-weight: 600;
      }
    }
  }
  .assets-list {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .assets-item {
      width: 100%;
      margin: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 36px;
        height: 36px;
      }
      .name-desc-div {
        margin: 0 12px;

        .name {
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
        .desc {
          margin-top: 4px;
          font-size: 12px;
          color: #808080;
        }
      }
      .number-value-div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .number {
          font-size: 16px;
          color: #212121;
        }
        .value {
          margin-top: 4px;
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }
  .move-div {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 80px;
    width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // font-size: 14px;
    // color: #D1840A;
    display: flex;
    flex-direction: row;

    .announcement {
      position: relative;
      width: fit-content;
      animation: move 5s linear infinite;
      padding-left: 50px;
    }
    .announcement::after {
      position: absolute; 
      right: -100%;
      content: attr(text);
    }
    .announcement-text {
      font-size: 14px;
      color: #D1840A;
      margin: 0 16px;
    }
    @keyframes move {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
}