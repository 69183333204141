.register-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    .back-button {
      width: 24px;
      height: 24px;
      padding: 10px;
      margin-left: 14px;
      background-color: white;
    }
  }
  .title {
    width: calc(100% - 48px);
    font-size: 32px;
    font-weight: 600;
    color: #212121;
    text-align: left;
    margin: 8px 24px;
  }
  .select-container {
    width: calc(100% - 48px);
    margin: 32px 24px 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .select-menu {
      margin-right: 20px;
      font-size: 14px;
      // font-weight: 600;
      color: #808080;
    }
    .active {
      color: #212121;
      position: relative;
    }
    // .active::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   bottom: -4px;
    //   right: 0;
    //   height: 2px;
    //   background-color: #D1840A;
    //   border-radius: 1px;
    // }
  }
  .input-container {
    margin: 24px;
    margin-top: 0;
    width: calc(100% - 48px);
    height: 50px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    .country-code {
      font-size: 16px;
      color: #212121;

      &:after {
        display: inline-block;
        content: "";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #B9BABA;
        margin-left: 6px;
        margin-right: 13px;
        vertical-align: middle;
      }
    }
    .input-div {
      flex: 1;
      --font-size: 16px;
      --color: #212121;
      
      .adm-input-element {
        line-height: 16px;
      }
    }
  }
  .input-container-active {
    border-color: #D1840A;
  }
  .referral-id {
    font-size: 14px;
    color: #212121;
    text-align: left;
    width: calc(100% - 48px);
    margin: 0 24px;
    margin-bottom: 16px;
  }
  .referral-id-container {
    margin: 6px 24px 40px 24px;
    width: calc(100% - 48px);
    height: 50px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    background-color: #F7F7F7;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 16px;
    color: #808080;
  }
  .next-button {
    width: calc(100% - 188px);
    height: 50px;
    border-radius: 25px;
    background-color: #D1840A;
    box-shadow: none;
    font-size: 16px;
    color: white;

    &::before {
      border-radius: 25px;
    }
  }
  .mask-container {
    .bg-container {
      width: calc(100% - 64px);
      height: calc(100% - 156px);
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;

      .head-container {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .empty-div {
          width: 24px;
          height: 24px;
          margin: 0 16px;
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #212121;
          text-align: center;
        }
        .close-button {
          width: 24px;
          padding: 10px;
          margin: 0 6px;
        }
      }
      .body-container {
        width: 100%;
        height: calc(100% - 60px);
        overflow: hidden;
        
        .body-scroll {
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;

          .content-list {
            width: 100%;
            display: flex;
            flex-direction: column;

            .section-container {
              margin: 5px 0;

              .section-key {
                font-size: 12px;
                color: #808080;
                margin-left: 16px;
              }
              .section-value-list {
                display: flex;
                flex-direction: column;
                margin: 5px 0;

                .section-value-item {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 8px 0;

                  &:active {
                    background-color: #f5f6f7;
                  }

                  .value-name {
                    font-size: 16px;
                    color: #212121;
                    margin-left: 16px;
                  }
                  .value-code {
                    flex: 1;
                    font-size: 16px;
                    color: #808080;
                    margin-left: 10px;
                  }
                  .checked {
                    width: 18px;
                    margin-right: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}