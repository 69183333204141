.apply-info-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .process {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      column-gap: 8px;

      .line {
        width: 48px;
        height: 4px;
        border-radius: 3px;
        background-color: #E6E6E6;
      }
      .active {
        background-color: #D1840A;
      }
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
  }
  .session-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input {
      background-color: #F7F7F7;
      border-radius: 8px;
      margin-top: 6px;
      padding: 14px 16px;
      box-sizing: border-box;
      border: 1px solid #F7F7F7;
      
      .adm-input-element {
        font-size: 16px;
        color: #212121;
      }
      &:focus-within {
        border: 1px solid #212121;
      }
    }
    .input-error:not(focus-within) {
      border: 1px solid red;
    }
  }
  .flex1 {
    flex: 1;
  }
  .next-Button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin: 20px 0;
  }
}