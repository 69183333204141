.join-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .bg-image {
    width: 100vw;
    height: 100vw;
  }
  .body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      padding: 24px;
      padding-bottom: 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: 600;
      color: #212121;
      line-height: 35px;
    }
    .content {
      width: 100%;
      padding: 15px 24px;
      box-sizing: border-box;
      font-size: 14px;
      color: #898989;
      line-height: 2;
    }
    .register-button {
      width: calc(100% - 48px);
      height: 48px;
      border-radius: 48px;
      background-color: #D1840A;
      box-shadow: none;
      font-size: 16px;
      color: white;
      margin-bottom: 15px;
    }
    .login-button {
      width: calc(100% - 48px);
      margin-bottom: 30px;
      height: 48px;
      border-radius: 48px;
      box-shadow: none;
      font-size: 16px;
      border: 1px solid #212121;
      box-sizing: border-box;
      color: #212121;
    }
  }
}