.apply-update-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .process {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: row;
      column-gap: 8px;

      .line {
        width: 48px;
        height: 4px;
        border-radius: 3px;
        background-color: #E6E6E6;
      }
      .active {
        background-color: #D1840A;
      }
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .session-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    padding: 16px 0;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      flex: 1;
    }
    .upload {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .session-image {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 12px;

    img {
      width: calc(50% - 6px);
      height: 100px;
      object-fit: cover;
    }
    p {
      width: calc(50% - 6px);
      height: 100px;
      text-align: center;
      line-height: 100px;
      font-size: 14px;
      color: #808080;
    }
  }
  .txt {
    font-size: 12px;
    white-space: pre-wrap;
    line-height: 1.5;
    color: #808080;
  }
  .example {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    img {
      width: 100%;
      &:not(:first-child) {
        margin-top: -50px;
      }
    }
  }
}