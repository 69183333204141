.browser-page {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .image1 {
    width: 100%;
  }
  .image2 {
    width: 85px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}