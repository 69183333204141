.p_success {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #D1840A 0%, #F2AC1D 100%), linear-gradient(180deg, #D1840A 0%, #FFF 100%), #FFF;
  position: relative;

  img {
    width: 280px;
    margin-top: 60px;
  }
  .title {
    margin-top: 60px;
    font-size: 32px;
    color: white;
    font-weight: 600;
  }
  .content {
    margin-top: 12px;
    color: #FFE1A6;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .button {
    position: absolute;
    left: 16px;
    bottom: 15px;
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: white;
    font-size: 16px;
    color: #D1840A;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}