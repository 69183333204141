.announcement-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      box-sizing: border-box;

      .title {
        width: 100%;
        color: #212121;
        font-size: 16px;
        line-height: 1.5;
        word-break: break-all;
      }
      .time {
        margin-top: 12px;
        font-size: 12px;
        color: #808080;
      }
    }
  }
}
.announcement-detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 18px;
      color: #212121;
      font-weight: 600;
      width: 100%;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
  .content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .title {
      width: 100%;
      color: #212121;
      font-size: 18px;
      line-height: 1.5;
      word-break: break-all;
    }
    .content {
      width: 100%;
      color: #808080;
      font-size: 16px;
      line-height: 1.5;
      margin-top: 15px;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .time {
      color: #808080;
      font-size: 14px;
      margin-top: 15px;
    }
  }
}