.manager-address-page {
  width: 100%;
  min-height: 100vh;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .navbar {
    width: 100%;
    height: 44px;
    position: relative;

    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .title {
    padding: 8px 0;
    font-size: 32px;
    color: #212121;
    font-weight: 600;
  }
  .session-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .name {
      font-size: 14px;
      color: #212121;
    }
    .input {
      background-color: #F7F7F7;
      border-radius: 8px;
      margin-top: 6px;
      padding: 14px 16px;
      box-sizing: border-box;
      border: 1px solid #F7F7F7;
      
      .adm-input-element {
        font-size: 16px;
        color: #212121;
      }
      &:focus-within {
        border: 1px solid #212121;
      }
    }
    .input-error:not(focus-within) {
      border: 1px solid red;
    }
    .input-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      background-color: #F7F7F7;
      margin-top: 6px;
      padding: 14px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #F7F7F7;

      .code-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2px;

        p {
          font-size: 16px;
          color: #212121;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .input2 {
        .adm-input-element {
          font-size: 16px;
          color: #212121;
        }
      }
      &:focus-within {
        border: 1px solid #212121;
      }
    }
  }
  .flex1 {
    flex: 1;
  }
  .next-Button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    background-color: #D1840A;
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin: 20px 0;
  }
}
.mask-container {
  .bg-container {
    width: calc(100% - 64px);
    height: calc(100% - 156px);
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;

    .head-container {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .empty-div {
        width: 24px;
        height: 24px;
        margin: 0 16px;
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #1B1B1B;
        text-align: center;
      }
      .close-button {
        width: 24px;
        padding: 10px;
        margin: 0 6px;
      }
    }
    .body-container {
      width: 100%;
      height: calc(100% - 60px);
      overflow: hidden;
      
      .body-scroll {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        .content-list {
          width: 100%;
          display: flex;
          flex-direction: column;

          .section-container {
            margin: 5px 0;

            .section-key {
              font-size: 12px;
              font-weight: 400;
              color: #9FA0A0;
              margin-left: 16px;
            }
            .section-value-list {
              display: flex;
              flex-direction: column;
              margin: 5px 0;

              .section-value-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 8px 0;

                &:active {
                  background-color: #f5f6f7;
                }

                .value-name {
                  font-size: 16px;
                  font-weight: 400;
                  color: #1B1B1B;
                  margin-left: 16px;
                }
                .value-code {
                  flex: 1;
                  font-size: 16px;
                  font-weight: 400;
                  color: #9FA0A0;
                  margin-left: 10px;
                }
                .checked {
                  width: 18px;
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.m_modal_logout {
  padding: 0;
  overflow-y: hidden;

  .adm-modal-content {
    padding: 0;
    overflow-y: hidden;
  }
  .modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      color: #212121;
      font-weight: 600;
      text-align: center;
      margin-top: 20px;
    }
    .buttons-div {
      width: 100%;
      height: 48px;
      margin-top: 25px;
      border-top: 1px solid #F7F7F7;
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        height: 100%;
        background-color: transparent;
        border-style: none;
        font-size: 16px;
        border-radius: 0;
      }
      .cancle {
        color: #212121;
      }
      .confirm {
        color: #EA3C32;
        border-left: 1px solid #F7F7F7;
      }
    }
  }
}
.adm-modal-footer.adm-space {
  display: none;
}